import Turbolinks from 'turbolinks'
Turbolinks.start()
Turbolinks.setProgressBarDelay(0)

global.Rails = require('@rails/ujs')
Rails.start()

global.Activestorage = require('@rails/activestorage')
Activestorage.start()

global.$ = $
global.jQuery = jQuery

require("channels")

require("javascripts/jquery-scrollLock.min.js")
require("javascripts/jquery.scrollbar.min.js")
require("javascripts/argon.js")
import 'javascripts/components/clients/form'
import 'javascripts/components/documents/move_to'

global.toastr = require('toastr/toastr')
global.select2 = require('select2/dist/js/select2.full')
global.Dropdown = require('bootstrap/js/dist/dropdown')
global.Tab = require('bootstrap/js/dist/tab')
import 'bootstrap/js/dist/tooltip'
import 'chart.js'
import 'popper.js'
// import 'chartjs-plugin-crosshair/dist/chartjs-plugin-crosshair'

import 'javascripts/ui/select2'
import 'javascripts/ui/flash'
import 'javascripts/ui/modal'

import 'javascripts/general'
