$(document).on('ready turbolinks:load', () => initSelect2());

global.initSelect2 = function (selector = null, options = {}) {
  const $forms = $(selector || 'form');
  const $selects = $forms.find('select');

  $selects.each(function () {
    const props = this.dataset;

    $(this).select2({
      allowClear: false,
      minimumResultsForSearch: -1,
      ...options,
      ...props,
    });
  });
}
