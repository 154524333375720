$(document).on('ready turbolinks:load', function() {
  initOverflowDropdown()
  $('body').on('change', 'input[type=file]', function () {
    const { value } = this
    $(this).siblings('.file-chosen').html(value)
  })
})

global.initOverflowDropdown = function () {
  let $dropdownMenu = null

  $('.table .dropdown').on('shown.bs.dropdown', function () {
    const $dropdown = $(this)
    $dropdownMenu = $dropdown.find('.dropdown-menu')

    if (!$dropdownMenu) return

    $('body').append($dropdownMenu.detach());
  });

  $('.table .dropdown').on('hidden.bs.dropdown', function () {
    const $dropdown = $(this)

    if ($dropdownMenu) $dropdown.append($dropdownMenu.detach());
    $dropdownMenu = null
  });
}
