$(document).on('turbolinks:load', function() {
  $('body').on('change', '.move-to-folder-checkbox', function() {
    let url;
    const token = $('meta[name="csrf-token"]').attr('content');
    const documentId = $(this).closest('.dropdown').data('document-id');
    const currentFolderId = $(this).closest('.dropdown').data('current-folder-id');
    const clientId = $(this).closest('.dropdown').data('client-id');
    const companyId = $(this).closest('.dropdown').data('company-id');

    if (clientId) {
      url = `/clients/${clientId}/documents/${documentId}/move_to`;
    } else if (companyId) {
      url = `/companies/${companyId}/documents/${documentId}/move_to`;
    }

    $.ajax({
      url,
      method: 'patch',
      dataType: 'script',
      headers: {
        'X-CSRF-Token': token,
      },
      data: { document: { folder_ids: ['', $(this).val()] }, current_folder_id: currentFolderId },
    });
  })
});
